<template>
  <Content class="market-place" aside-caption="filtr meta fighter">
    <template slot="aside">
      aside
    </template>
    <template slot="content">
      content
    </template>
  </Content>
</template>

<script>
import Content from '@/components/common/content.vue'
export default {
  name: 'MarketPlace',
  components: {
    Content,
  },
}
</script>

<style>
</style>
