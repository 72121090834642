var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Content",
    {
      staticClass: "market-place",
      attrs: { "aside-caption": "filtr meta fighter" },
    },
    [
      _c("template", { slot: "aside" }, [_vm._v(" aside ")]),
      _c("template", { slot: "content" }, [_vm._v(" content ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }