// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".content {\n  width: 100%;\n  flex-grow: 1;\n  min-height: 50vh;\n}\n.content--grid {\n  max-width: 1650px;\n  padding: 0 15px;\n  margin: auto;\n  display: grid;\n  grid-template-columns: 0.2fr 1fr;\n  gap: 1.5em;\n}\n.content__aside {\n  color: #fff;\n}\n.content__aside-caption {\n  padding: 20px 45px;\n  text-transform: uppercase;\n  font-weight: 600;\n  font-size: 19px;\n  background-color: #4F4F4F;\n}\n.content__main {\n  width: 100%;\n}\n.content__main--overflow-hidden {\n  overflow: hidden;\n}\n.content__main--pure {\n  max-width: 1650px;\n  padding: 35px 15px;\n  margin: auto;\n}\n@media screen and (max-width: 640px) {\n.content__main {\n    padding-right: 15px;\n    padding-left: 15px;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
