var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["content", { "content--grid": _vm.canShowAside }] },
    [
      _vm.canShowAside
        ? _c(
            "aside",
            { staticClass: "content__aside" },
            [
              _vm.asideCaption
                ? _c("h3", { staticClass: "content__aside-caption" }, [
                    _vm._v(_vm._s(_vm.asideCaption)),
                  ])
                : _vm._e(),
              _vm._t("aside"),
            ],
            2
          )
        : _vm._e(),
      _c(
        "main",
        {
          class: [
            "content__main",
            { "content__main--pure": !_vm.canShowAside },
            { "content__main--overflow-hidden": _vm.isOverflowHidden },
          ],
        },
        [_vm._t("content")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }