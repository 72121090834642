<template>
  <div
      :class="['content', { 'content--grid': canShowAside }]"
  >
    <aside v-if="canShowAside" class="content__aside">
      <h3 v-if="asideCaption" class="content__aside-caption">{{ asideCaption }}</h3>

      <slot name="aside" />
    </aside>
    <main
        :class="[
            'content__main',
            { 'content__main--pure': !canShowAside },
            { 'content__main--overflow-hidden': isOverflowHidden },
        ]"
    >
      <slot name="content" />
    </main>
  </div>
</template>

<script>
export default {
  name: "Content",
  props: {
    asideCaption: { type: String, default: '' },
    isShownAside: {type: Boolean, default: null},
    isOverflowHidden: {type: Boolean, default: false},
  },
  computed: {
    canShowAside() {
      if (this.isShownAside === null) {
          return this.$slots.aside;
      }
      return this.isShownAside;
    },
  },
}
</script>

<style lang="scss">

.content {
  width: 100%;
  flex-grow: 1;
  min-height: 50vh;

  &--grid {
    max-width: 1650px;
    padding: 0 15px;
    margin: auto;
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    gap: 1.5em;
  }
}
.content__aside {
  color: #fff;
}
.content__aside-caption {
  padding: 20px 45px;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 19px;

  background-color: #4F4F4F;
}
.content__main {
  width: 100%;


  &--overflow-hidden {
    overflow: hidden;
  }
}

.content__main--pure {
  max-width: 1650px;
  padding: 35px 15px;
  margin: auto;
}

@media screen and (max-width: $bp_mb) {
  .content__main {
    padding-right: 15px;
    padding-left: 15px;
  }
}

</style>
